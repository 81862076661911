'use client';

import React, { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PromoBanner } from 'ui/components/PromoBanner/PromoBanner';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { featureFlagNames } from '@/client/featureFlagClient';
import contentfulClient from '@/client/contentfulClient';
import { useTranslation } from '@whoop/i18n/lang/client';
import { BANNER_THEMES } from 'ui/types/PromoCodeThemes';
import { formatDate } from '@whoop/i18n';

export const PromoBannerWrapper = (): JSX.Element | null => {
  const page = useGetCurrentPage();
  const {
    siteWidePromoContent,
    promoContent,
    featureFlags,
    language,
    region,
    siteBannerText,
    setSiteBannerText,
    ecommCatalog,
  } = useSiteStore(
    useShallow((state) => ({
      siteWidePromoContent: state.siteWidePromoContent,
      promoContent: state.promoContent,
      featureFlags: state.featureFlags,
      language: state.language,
      region: state.region,
      siteBannerText: state.siteBannerText,
      setSiteBannerText: state.setSiteBannerText,
      ecommCatalog: state.ecommCatalog,
    })),
  );
  const siteBannerId = 'SITEBANNER';
  const { t } = useTranslation('joinLandingPage', language);

  const getSiteBannerText = async () => {
    if (featureFlags[featureFlagNames.DISABLE_IL_JF] && region === 'IL') {
      const israelText = t('notShippingToIsrael');
      setSiteBannerText(israelText);
    } else if (featureFlags[featureFlagNames.SITE_BANNER]) {
      try {
        const bannerText = await contentfulClient.getSiteWideBannerContent({
          language,
          bannerId: siteBannerId,
        });
        setSiteBannerText(bannerText);
      } catch (_err) {
        // If getting content fails, just don't show the banner
      }
    }
  };

  useEffect(() => {
    if (!siteBannerText) {
      getSiteBannerText();
    }
  }, [language, featureFlags]);

  const discountValidUntil = useMemo(() => {
    return ecommCatalog.memberships
      .flatMap((mem) => mem.variants)
      .map((variant) => variant.price?.discounted?.discount.valid_until)
      .find((validUntil) => validUntil);
  }, [ecommCatalog]);

  const validUntil = discountValidUntil
    ? t('ends', {
        date: formatDate(new Date(discountValidUntil), language, {
          month: 'short',
          day: 'numeric',
        }),
      })
    : undefined;

  if (page === 'Order' || page === 'Receipt') {
    return null;
  }

  // If site wide banner content, show even if partnership
  if (siteBannerText) {
    return <PromoBanner content={siteBannerText} />;
  }

  return !promoContent?.partnership_name && siteWidePromoContent?.bannerText ? (
    <PromoBanner
      content={siteBannerText ?? siteWidePromoContent.bannerText}
      theme={BANNER_THEMES[siteWidePromoContent.theme ?? 'default']}
      tagText={validUntil}
    />
  ) : null;
};
