'use client';

import { useTranslation } from '@whoop/i18n/lang/client';
import Image from 'next/image';
import WhoopFooterLogoDark from 'ui/icons/svg/whoop-footer-logo-dark.svg';
import { usePathname } from 'next/navigation';
import type { Language } from '@whoop/i18n';
import CountrySelectorWrapper from '@/components/CountrySelectorWrapper/CountrySelectorWrapper';

interface FooterProps {
  language: Language;
}

export default function Footer({ language }: FooterProps): JSX.Element {
  const { t } = useTranslation('joinLandingPage', language);
  const pathname = usePathname();

  const linkItems = [
    {
      label: '1',
      link: 'https://whoop.com/termsofuse',
      desktopText: t('footerTermsOfUse'),
    },
    {
      label: '2',
      link: 'https://whoop.com/termsofsale',
      desktopText: t('footerTermsOfSale'),
    },
    {
      label: '3',
      link: 'https://whoop.com/privacy',
      desktopText: t('footerPrivacy'),
    },
    {
      label: '4',
      link: 'https://api.prod.whoop.com/url-mapping-service/v1/RETURN_POLICY?_ga=2.75348950.557659886.1705944237-520215418.1697553817',
      desktopText: t('footerRefund'),
    },
    {
      label: '5',
      link: 'https://whoop.com/sales-campaign-disclaimers',
      desktopText: t('footerHolidaySales'),
    },
  ];

  return (
    <footer className='bg-gray-a800 flex w-full items-center justify-between px-6 py-4 md:py-10'>
      <div className='flex flex-col items-start sm:flex-row sm:items-center sm:gap-6 md:pl-6'>
        <div>
          <Image
            alt='Whoop logo'
            height={32}
            src={WhoopFooterLogoDark}
            width={32}
          />
        </div>
        <div className='flex w-fit flex-col items-start gap-1 md:flex-row md:items-center md:gap-6'>
          <p className='text-gray-b50 text-xs uppercase'>
            {t('copyright', { year: new Date().getFullYear() })}
          </p>
          <ul className='flex flex-col gap-1 text-xs uppercase md:flex-row md:gap-6'>
            {linkItems.map((item) => (
              <li key={item.label}>
                <a
                  className='text-white'
                  href={item.link}
                  rel='noopener'
                  target='_blank'
                >
                  {item.desktopText}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        {!pathname.includes('receipt') ? <CountrySelectorWrapper /> : null}
      </div>
    </footer>
  );
}
