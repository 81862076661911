import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import type { Language } from '@whoop/i18n/types/internationalization';
import type { SiteWideMetadataContent } from 'types/siteWideMetadataTypes';
import { env } from 'env';
import type { SiteWidePromoContent } from '../types/siteWidePromoTypes';

export interface ContentfulRequestParams {
  language: Language;
  promoId: string;
}

export interface HeroCarouselData {
  default: { items: HeroCarousel[] };
  region: { items: HeroCarousel[] };
}

export interface HeroCarousel {
  name: string;
  itemsCollection: {
    items: HeroCarouselItem[];
  };
}

export interface HeroCarouselItem {
  caption: string;
  backgroundImage: {
    url: string;
    title: string;
  };
  secondaryImage: {
    url: string;
    title: string;
  };
  captionColor: string;
}

export class ContentfulClient {
  baseUrl = env.NEXT_PUBLIC_CONTENTFUL_GRAPHQL_BASE_URL;
  spaceId = env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
  #isPreview = env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'enabled';
  #contentfulAccessToken = `Bearer ${env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN}`;

  async fetchContent(body, revalidate = 300): Promise<Response> {
    return fetch(`${this.baseUrl}/${this.spaceId}`, {
      next: { revalidate },
      method: 'POST',
      headers: {
        Authorization: this.#contentfulAccessToken,
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  async getSiteWideMetadataContent(
    language: Language,
  ): Promise<SiteWideMetadataContent> {
    // Fallbacks are used if/when a Contentful request errors out
    const fallbackMetadataTitle = 'Join WHOOP';
    const fallbackMetadataDescription =
      "The most advanced fitness and health wearable. Get personalized insights on your body's recovery, strain, sleep, and health with in-app coaching features designed to help you unlock your best self.";
    const fallbackMetadataImageURL =
      'https://images.ctfassets.net/am71vbtj0o80/XzUa9TF9ZkBwfY6jRXgAo/261c39ea90d1616e2cb1dcbe84c4b635/meta-image.png';
    const fallbackMetadataContent = {
      title: fallbackMetadataTitle,
      description: fallbackMetadataDescription,
      imageURL: fallbackMetadataImageURL,
    };

    try {
      const res = await this.fetchContent(
        JSON.stringify({
          query: `query SEO($language: String!, $isPreview: Boolean) {
          seoMetadataCollection(locale: $language, preview: $isPreview) {
            items {
              title,
              description,
              image {
                url
              }
            }
          }
        }`,
          variables: {
            language,
            isPreview: this.#isPreview,
          },
        }),
        0,
      );

      if (res.ok) {
        const parsedResponse = await res.json();
        const seoTitle =
          parsedResponse.data?.seoMetadataCollection?.items[0]?.title ??
          fallbackMetadataTitle;
        const seoDescription =
          parsedResponse.data?.seoMetadataCollection?.items[0]?.description ??
          fallbackMetadataDescription;
        const seoImageURL =
          parsedResponse.data?.seoMetadataCollection?.items[0]?.image?.url ??
          fallbackMetadataImageURL;
        return {
          title: seoTitle,
          description: seoDescription,
          imageURL: seoImageURL,
        };
      }
      return fallbackMetadataContent;
    } catch (_error) {
      const error = _error as Error;
      console.error(
        `${error.name} ${error.message}`,
        'SEO metadata request failed',
      );
      return fallbackMetadataContent;
    }
  }

  async getSiteWideBannerContent({
    language,
    bannerId,
  }: {
    language: Language;
    bannerId: string;
  }): Promise<string | undefined> {
    try {
      const res = await this.fetchContent(
        JSON.stringify({
          query: `query Promo(${
            bannerId ? '$bannerId: String, ' : ''
          }$language: String!, $isPreview: Boolean) {
          sitewideBannerCollection(${
            bannerId ? 'where: {id: $bannerId}, ' : ''
          }locale: $language, preview: $isPreview) {
            items {
              bannerText
            }
          }
        }`,
          variables: {
            language,
            isPreview: this.#isPreview,
            bannerId,
          },
        }),
      );

      if (res.ok) {
        const parsedResponse = await res.json();
        const entry = parsedResponse.data?.sitewideBannerCollection?.items[0];
        const bannerText = entry?.bannerText;
        return bannerText;
      }
      return undefined;
    } catch (_error) {
      const error = _error as Error;
      console.error(
        `${error.name} ${error.message}`,
        'sitewide banner request failed',
      );
      return undefined;
    }
  }

  async getSiteWidePromoContent({
    language,
    discountId,
  }: {
    language: string;
    discountId: string;
  }): Promise<SiteWidePromoContent | undefined> {
    try {
      const res = await this.fetchContent(
        JSON.stringify({
          query: `query Promo($language: String!, $isPreview: Boolean, $discountId: String) {
          ecommSiteWidePromoCollection(where: {promoMapping_contains_some: [$discountId]}, 
          locale: $language, 
          preview: $isPreview) {
            items {
              name
              landingPageEyebrow
              banner {
                bannerText: richBannerText {
                  json
                }
                id
              }
              promoMapping
              theme
              productListCollection {
                items {
                  tagText
                  product
                  tagImage {
                    url(
                      transform: {
                        width: 20
                        height: 20
                        quality: 100
                      }
                    )
                  }
                  product_data {
                    productType {
                      name
                    }
                  }
                }
              }
            }
          }
        }`,
          variables: {
            language,
            isPreview: this.#isPreview,
            discountId,
          },
        }),
      );

      if (res.ok) {
        const parsedResponse = await res.json();
        const entry =
          parsedResponse.data?.ecommSiteWidePromoCollection?.items[0];
        const bannerText = entry?.banner?.bannerText.json;
        return {
          bannerText: documentToHtmlString(bannerText),
          landingPageEyebrow: entry?.landingPageEyebrow,
          // Leaving here so we can deprecate it in the future without a huge refactor right now
          promoEndDate: null,
          theme: entry?.theme,
          productList: entry?.productListCollection?.items,
        };
      }

      return;
    } catch (_error) {
      const error = _error as Error;
      console.error(
        `${error.name} ${error.message}`,
        'promo banner request failed',
      );
      return undefined;
    }
  }

  async getHeroCarouselContent({
    language,
    region,
  }): Promise<HeroCarouselData | undefined> {
    try {
      const res = await this.fetchContent(
        JSON.stringify({
          query: `
          # This is a fragment called fields, for the Hero Carousel Collection. We can reuse it in multiple queries
          fragment fields on HeroCarouselCollection {
            items {
              itemsCollection {
                items {
                  caption
                  backgroundImage {
                    url
                    title
                  }
                  secondaryImage {
                    url
                    title
                  }
                  captionColor
                }
              }
            }
          }
          
          query Carousel($language: String!, $isPreview: Boolean, $region: String) {
            default: heroCarouselCollection(where: { region: "ALL" }, locale: $language, preview: $isPreview, limit: 1) {
              items {
                name
              }
              ...fields
            }
            region: heroCarouselCollection(where: { region: $region }, locale: $language, preview: $isPreview, limit: 1) {
              items {
                name
              }
              ...fields
            }
          }
        `,
          variables: {
            language,
            isPreview: this.#isPreview,
            region,
          },
        }),
      );

      if (res.ok) {
        const parsedResponse: { data: HeroCarouselData } = await res.json();
        const entry = parsedResponse.data;
        return entry;
      }
      return undefined;
    } catch (_error) {
      const error = _error as Error;
      console.error(
        `${error.name} ${error.message}`,
        'No hero carousel content found',
      );
      return undefined;
    }
  }
}

export default new ContentfulClient();
