export const themes = {
  gold: 'gold-palette',
  green: 'green-palette',
  black: 'black-palette',
  red: 'red-palette',
  electricGreen: 'electric-green-palette',
} as const;

export const MEM_CARD_THEMES = {
  default: themes.electricGreen,
  gold: themes.gold,
  green: themes.green,
  red: themes.red,
  black: themes.black,
} as const;

export const BANNER_THEMES = {
  default: themes.black,
  gold: themes.gold,
  green: themes.green,
  red: themes.red,
  black: themes.black,
} as const;

export type PromoTheme = (typeof themes)[keyof typeof themes];
export type MembershipOptionCardThemes = keyof typeof MEM_CARD_THEMES;
export type BannerThemes = keyof typeof BANNER_THEMES;
