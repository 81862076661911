'use client';

import { cva } from 'class-variance-authority';
import { cltw } from '../../utils';
import Success from '../../icons/icons/Toast/success';
import Info from '../../icons/icons/Toast/info';
import Attention from '../../icons/icons/Toast/attention';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from './Toast';
import { useToast } from './use-toast';

const iconBgVariants = cva('flex rounded-md px-2.5 py-2 text-white', {
  variants: {
    variant: {
      default: 'bg-gray-400',
      info: 'bg-blue-strain',
      success: 'bg-green-a300',
      warning: 'bg-yellow-600',
      error: 'bg-red-400',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const icon: Record<string, JSX.Element> = {
  info: <Info className='scale-75' />,
  success: <Success className='scale-75' />,
  // colors should match variants
  warning: <Attention className='scale-125' color='#FFA722' />,
  error: <Attention className='scale-125' color='#FF0026' />,
};

export function Toaster(): JSX.Element {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(
        ({ id, title, description, action, variant, duration, ...props }) => {
          return (
            <Toast duration={duration} key={id} variant={variant} {...props}>
              <div className='flex w-full flex-row items-center justify-start space-x-4'>
                {/* Icon component */}
                {variant !== 'default' ? (
                  <div
                    className={cltw(
                      iconBgVariants({ variant }),
                      'flex rounded-md px-2.5 py-2 text-white',
                    )}
                  >
                    {icon[variant ?? 'info']}
                  </div>
                ) : null}
                {/* Toast content */}
                <div className='grid gap-1'>
                  {title ? <ToastTitle>{title}</ToastTitle> : null}
                  {description ? (
                    <ToastDescription>{description}</ToastDescription>
                  ) : null}
                </div>
              </div>
              {action}
              <ToastClose />
            </Toast>
          );
        },
      )}
      <ToastViewport />
    </ToastProvider>
  );
}
